import { useCallback } from "react";
import { SortableListProps } from "./sortable-list.props";
import "./sortable-list.styles.scss";
import SortableListPresentation from "./sortable-list-component";
import { useSelector } from "react-redux";
import { getTOCManagementState, setCarePlanData } from "state/feature/toc-management/toc-management.slice";
import TocEmptyImage from "shared/assets/images/toc-empty-state.svg";
import update from "immutability-helper";
import { useAppDispatch } from "state/store";

const SortableList = (props: SortableListProps) => {
  const dispatch = useAppDispatch();
  const { carePlanData } = useSelector(getTOCManagementState);
  const { toc, intakeType } = props;

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    const newCarePlan = update(carePlanData, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, carePlanData[dragIndex] as any],
      ],
    });
    dispatch(setCarePlanData(newCarePlan));
  };

  return carePlanData.length > 0 ? (
    <SortableListPresentation key={1} list={carePlanData} moveCard={moveCard} toc={toc} intakeType={intakeType} />
  ) : (
    <div className="toc-empty-image">
      <img src={TocEmptyImage} alt="toc-empty" />
      <div className="empty-message-container">
        <div className="empty-message">No Care Plan available to show</div>
        <div className="empty-message-subline">Select an ‘Option’ to view the care plan</div>
      </div>
    </div>
  );
};
export default SortableList;
