import { useContext, useEffect, useState } from "react";
import Icon from "components/Icon/icon.component";
import Player from "components/Player/player.component";
import { useDispatch, useSelector } from "react-redux";
import "./row-icons.styles.scss";
import { RowIconsProps } from "./types";
import { toast } from "react-toastify";
import { getAuth } from "state/feature/auth/auth.slice";
import { getCallState, saveCurrentCall } from "state/feature/call/call.slice";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import { useAppDispatch } from "state/store";
import { getCommonState, setIsOpenAudioModal, setIsOpenCallModal } from "state/feature/common/common.slice";
import { acknowledgeMissedCall, getWhiteLabelPhoneNumberAsync } from "state/feature/navigator/navigator.action";
import { useTwilioClientService } from "shared/services/twilio/twilio-client.service";
import { isMobile } from "react-device-detect";
import Button from "components/Button/button.component";
import {
  CALL_NOT_READ,
  CALL_READ,
  NO_PHONE_NUMBER_FOR_PRACTICE,
  TOAST_MESSAGE_NO_PHONE_NUMBER_ASSIGNED,
} from "shared/constant/commonConstants";
import { InboundRowIcons } from "./InboundRowIcons/inbound-row-icons";
import { getNavigatorState, setCallReadStatus } from "state/feature/navigator/navigator.slice";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";
import AudioPlayerModal from "components/Modal/audio-player-modal/audio-player-modal.component";
import { CallingStatus } from "shared/enums/calling-status.enum";
import { CallContext } from "App";

const RowIcons = ({
  index,
  indexOfAudioPlayer,
  setIndexOfAudioPlayer,
  recordingUri,
  participants,
  clientID,
  isReply,
  voiceMailUrl,
  callerParticipantDetails,
  callStartTime,
}: RowIconsProps) => {
  useAxiosAuthenticated();
  const [isCallButtonClicked, setIsCallButtonClicked] = useState(false);
  const twilioClient = useTwilioClientService();
  const authState = useSelector(getAuth);
  const { twilioErrorCode, modal } = useSelector(getCommonState);
  const navigatorState = useSelector(getNavigatorState);
  const callState = useSelector(getCallState);
  const dispatch = useDispatch();
  const appDipatch = useAppDispatch();
  const { ongoingCall: isOngoingCall } = useContext(CallContext);

  const handleAudioPlayClick = () => {
    dispatch(setIsOpenAudioModal(true));
    setIndexOfAudioPlayer(index);
  };

  const handleAudioPlayerClose = () => {
    dispatch(setIsOpenAudioModal(false));
    setIndexOfAudioPlayer(-1);
  };

  const handleSidePopupClose = () => {
    dispatch(setIsOpenCallModal(false));
  };

  const getPreparedParticipantsForCall = () => {
    const preparedParticipantsForCall = participants.map((participant) => {
      const name = participant.id ? `${participant.name}` : participant.phoneNumber;
      if (navigatorState.currentUserProfile && participant.id === navigatorState.currentUserProfile.id) {
        return {
          phoneNumber: navigatorState.currentUserProfile.forwardingPhoneNumber,
          id: navigatorState.currentUserProfile.id,
          name: `${navigatorState.currentUserProfile.firstName} ${navigatorState.currentUserProfile.lastName}`,
          type: "Navigator",
        };
      }
      return {
        phoneNumber: participant.phoneNumber,
        id: participant.id ?? "",
        name,
        type: participant.type ?? "Unsaved",
      };
    });
    return preparedParticipantsForCall;
  };

  const makeACall = async () => {
    try {
      if (!isCallButtonClicked) {
        setIsCallButtonClicked(true);
        const { data, status } = await appDipatch(getWhiteLabelPhoneNumberAsync({ clientId: clientID })).unwrap();
        if (status === 204) {
          toast.error(NO_PHONE_NUMBER_FOR_PRACTICE, {
            containerId: "main",
            toastId: "call",
          });
        } else if (data && data.whiteLabelPhoneNumber) {
          const from = data.whiteLabelPhoneNumber;

          const to = participants
            .filter((x) => x.type.toLowerCase() !== "navigator")
            .map((participant) => participant.phoneNumber)
            .toString();

          const participantsOnCall = getPreparedParticipantsForCall();
          const postCallback = async () => {
            dispatch(saveCurrentCall(null));
            toast.dismiss();
          };
          const effectiveNavigator = {
            id: navigatorState.currentUserProfile?.id ? navigatorState.currentUserProfile?.id : "",
            name: `${navigatorState.currentUserProfile?.firstName ? navigatorState.currentUserProfile?.firstName : ""}${
              navigatorState.currentUserProfile?.lastName ? ` ${navigatorState.currentUserProfile?.lastName}` : ""
            }`,
            phoneNumber: navigatorState.currentUserProfile?.forwardingPhoneNumber
              ? navigatorState.currentUserProfile?.forwardingPhoneNumber
              : "",
          };
          localStorage.setItem("callStatus", CallingStatus.CONNECTED);
          await twilioClient.addParticipantsAndCreateConference(
            to,
            from,
            participantsOnCall,
            clientID,
            effectiveNavigator,
            -1,
            postCallback
          );
          handleSidePopupClose();
        } else {
          localStorage.setItem("callStatus", CallingStatus.DISCONNECTED);
          toast.error(TOAST_MESSAGE_NO_PHONE_NUMBER_ASSIGNED, {
            containerId: "main",
            toastId: "call",
          });
        }
        setIsCallButtonClicked(false);
      }
    } catch (error: any) {
      console.log(error);
      localStorage.setItem("callStatus", CallingStatus.DISCONNECTED);
      toast.error(
        "The microphone is currently disabled in your browser. You must enable the microphone and reload the browser (which disconnects the current call) to resolve the issue",
        { containerId: "main", toastId: "twilio-error" }
      );
      callState.twilioCallDevice.disconnectAll();
      setIsCallButtonClicked(false);
      dispatch(saveCurrentCall(null));
      toast.dismiss("call");
    }
  };

  const onCallButtonPressed = () => {
    if (!callState.currentCall) {
      makeACall();
    } else {
      toast.error("Another call is ongoing", {
        toastId: "call-ongoing",
        containerId: "main",
      });
    }
  };

  return isMobile ? (
    <Button
      text="Call"
      className="green-button call-button"
      icon="call-icon"
      iconClassName="phone-call-icon"
      buttonSize={18}
      onClick={() => {
        if (!callState.currentCall) {
          makeACall();
        } else {
          toast.error("Another call is ongoing", {
            toastId: "call-ongoing",
            containerId: "main",
          });
        }
      }}
    />
  ) : (
    <>
      {modal.isOpenAudioModal && index === indexOfAudioPlayer && voiceMailUrl ? (
        <AudioPlayerModal
          voiceMailUrl={voiceMailUrl}
          name={callerParticipantDetails ? callerParticipantDetails.name : ""}
          date={callStartTime}
          onClose={handleAudioPlayerClose}
        />
      ) : (
        <div className="row-icons">
          {isReply && (
            <div
              data-tip
              data-for="Call back"
              className={`call-icon ${
                twilioErrorCode ||
                isOngoingCall ||
                localStorage.getItem("callStatus") === CallingStatus.CONNECTED ||
                localStorage.getItem("callStatus") === CallingStatus.RINGING
                  ? "disabled"
                  : ""
              }`}
              onClick={() => {
                if (callerParticipantDetails?.status === "missed" && !callerParticipantDetails?.isMissedCallRead) {
                  appDipatch(
                    acknowledgeMissedCall({
                      participantId: callerParticipantDetails?.id,
                      callId: navigatorState.calls[index].id,
                    })
                  ).then((response: any) => {
                    if (response.error) {
                      toast.error(CALL_NOT_READ, {
                        toastId: "acknowledgement-error",
                        containerId: "main",
                      });
                    } else {
                      dispatch(setCallReadStatus({ index, participantDetail: callerParticipantDetails }));
                      toast.success(CALL_READ, {
                        toastId: "acknowledgement-sucess",
                        containerId: "main",
                      });
                    }
                  });
                }
                onCallButtonPressed();
              }}
            >
              <CustomToolTip text="Call back" id="Call back" place="top" />
              <Icon icon="call-icon" size={20} />
            </div>
          )}
          <InboundRowIcons
            voiceMailUrl={voiceMailUrl}
            handleAudioPlayClick={handleAudioPlayClick}
            clickedIndex={index}
            callerParticipantDetails={callerParticipantDetails}
          />
        </div>
      )}
    </>
  );
};

export default RowIcons;
