import { ChangeEvent, useCallback, useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/Button/button.component";
import Checkbox from "components/Checkbox/checkbox.component";
import "./toc-revision.styles.scss";
import ErrorIcon from "shared/assets/images/error.png";
import Icon from "components/Icon/icon.component";
import useAxiosAuthenticated from "shared/hooks/use-axios-wrapper.hook";
import {
  FACILITY_AND_HOME_ERROR,
  FACILITY_ERROR,
  IntakeTypeEnum,
  LOS_ERROR,
  LOS_VISIT_ERROR,
  NOTE_LIMIT,
  PAC_TYPE,
  PlannedUnplannedEnum,
  RiskLevelEnum,
  TocStatusEnum,
} from "../constants/index.constant";
import {
  changeTocStatus,
  deleteToc,
  getTOCManagementState,
  getTocEditForm,
  resetCarePlan,
  resetLocationValidation,
  setCarePlanData,
  setIsCustomisedCarePlanEnabled,
  setIsTocValueChanged,
  setOriginalCarePlanData,
  setTocEditForm,
  updateAchFacilites,
  updateTocList,
} from "state/feature/toc-management/toc-management.slice";
import { getTocHistory, getServiceTemplateData } from "state/feature/toc-management/toc-management.action";
import { ITransitionOfCare, ITransitionOfCareItems, CarePlan } from "state/types/toc-management-slice.type";
import TocChangeModal from "pages/task-management/transition-of-care/modals/toc-change-modal/toc-change-modal.component";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SortableList from "components/sortable-list/sortable-list.container";
import CustomDropDown from "components/CustomDropdown/custom-dropdown.component";
import { CarePlanServices } from "shared/enums/care-plan-services.enum";
import { isEmptyString } from "shared/methods/utilityFunctions";
import { setIsOpenAddServiceModal, setIsOpenTocReasonModal } from "state/feature/common/common.slice";
import CommonState from "components/common-state/common-state.component";
import { CommonStateType } from "components/common-state/common-state.enum";
import { getBrowserState, setShowUnsavedChangesModal } from "state/feature/task-management/task-management.slice";
import { ITab } from "state/types/task-management-slice.type";
import SingleSelectDropdownWithClearOption from "components/single-select-dropdown-with-clear-option/single-select-dropdown-with-clear-option.component";

interface TocRevisionProps {
  toc: ITransitionOfCare;
  taskId: string;
  handleSelectedToc: (toc: ITransitionOfCare) => void;
  handleSelectedCarePlan: (id: string) => void;
  copyOfSelectedCarePlan: string;
  customiseCarePlan: boolean;
}

const TocRevision = (props: TocRevisionProps) => {
  useAxiosAuthenticated();
  const dispatch = useDispatch();
  const {
    facilities,
    tocs,
    intake,
    revisionReasons,
    carePlans,
    carePlanData,
    allFacilities,
    riskLevel,
    originalCarePlanData,
    pacTypes,
    customiseReasons,
    isPlanned,
  } = useSelector(getTOCManagementState);
  const { toc, handleSelectedToc, handleSelectedCarePlan, copyOfSelectedCarePlan, customiseCarePlan } = props;
  const {
    id,
    intakeId,
    version,
    noteCareManager,
    notePhysician,
    anticipatedAcuteLOS,
    createdAt,
    approvedAt,
    isExpanded,
    transitionOfCareItems,
    status,
    isModified,
    history,
    additionalNote,
    approvedBy,
    reasonId,
    createdBy,
    standardCarePlanID,
    customizeReasonId,
    isCustomised,
  } = toc;
  const { currentTab, pages } = useSelector(getBrowserState);
  const [isRowHovered, setIsRowHovered] = useState(false);
  const tocEditForm = useSelector(getTocEditForm);
  const [isSubmitButtonPressed, setIsSubmitButtonPressed] = useState(false);
  const [selectedCarePlan, setSelectedCarePlan] = useState({ name: "", key: "" });
  const [intakeType, setIntakeType] = useState<IntakeTypeEnum>(IntakeTypeEnum.NEW);
  const [loading, setLoading] = useState(false);

  const isHighRisk =
    version > 1 &&
    ((!isEmptyString(riskLevel) && riskLevel === RiskLevelEnum.HIGH) ||
      (!isEmptyString(isPlanned) && isPlanned === PlannedUnplannedEnum.UNPLANNED));

  const getProviderName = useCallback(
    (providerId: number) => {
      if (allFacilities && allFacilities.length > 0 && providerId !== -1) {
        const facility = allFacilities.find((el) => el.id === providerId);
        return facility ? facility.providerName : "";
      } else {
        return "";
      }
    },
    [allFacilities]
  );
  const getTargetLos = useCallback((los: number) => {
    if (los === -1) {
      return "";
    }
    return los.toString();
  }, []);

  const initializeTocItemsForm = useCallback(() => {
    if (transitionOfCareItems && transitionOfCareItems.length > 0) {
      if (!approvedAt && (toc.status === TocStatusEnum.PENDING || toc.status === TocStatusEnum.NEW)) {
        setLoading(true);
        const originalCarePlanDataItems = transitionOfCareItems
          .filter((carePlanItem) => carePlanItem.pacTypeName !== PAC_TYPE.HOME_SERVICE)
          .map((carePlanItem) => {
            return {
              ...carePlanItem,
              longName: carePlanItem.pacTypeName,
              quantity: carePlanItem.targetLos,
            };
          });
        const isEpisodePresent = transitionOfCareItems.findIndex(
          (carePlanItem) => carePlanItem.pacTypeName.toLowerCase() === "episode"
        );
        if (isEpisodePresent === -1) {
          originalCarePlanDataItems.unshift({
            longName: "Episode",
            id: "-1",
            pacTypeName: "Episode",
            providerId: intake && intake.facilityId ? intake?.facilityId : -1,
            daysType: "day(s)",
            quantity: tocEditForm.acuteLos.value ? parseInt(tocEditForm.acuteLos.value) : anticipatedAcuteLOS,
            targetLos: tocEditForm.acuteLos.value ? parseInt(tocEditForm.acuteLos.value) : anticipatedAcuteLOS,
            admission: "after previous",
            pacTypeId:
              pacTypes.length > 0
                ? (pacTypes.find((pacType) => pacType.itemShortName.toLowerCase() === "episode")?.id as number)
                : -1,
          });
        }
        dispatch(setCarePlanData(originalCarePlanDataItems));
        dispatch(setOriginalCarePlanData(originalCarePlanData));
        const isHomeWithoutServicePresent = transitionOfCareItems.find(
          (carePlanItem: any) => carePlanItem.pacTypeName === PAC_TYPE.HOME_SERVICE
        );
        dispatch(
          setTocEditForm({
            ...tocEditForm,
            navigatorNotes: noteCareManager,
            additionalNotes: additionalNote,
            acuteLos: { value: anticipatedAcuteLOS ? anticipatedAcuteLOS.toString() : "", error: "" },
            isHomeWithNoServicesEnabled: isHomeWithoutServicePresent ? true : false,
          })
        );
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transitionOfCareItems, anticipatedAcuteLOS, pacTypes]);

  const getTocItem = (pacType: string) => {
    return transitionOfCareItems?.find((careItem) => careItem.pacTypeName === pacType);
  };

  const isIntakeOldOrNew = useCallback(() => {
    if (standardCarePlanID || (tocs.length === 1 && status === TocStatusEnum.NEW)) {
      setIntakeType(IntakeTypeEnum.NEW);
    } else {
      setIntakeType(IntakeTypeEnum.OLD);
    }
  }, [standardCarePlanID, status, tocs.length]);

  useEffect(() => {
    if (transitionOfCareItems && transitionOfCareItems.length > 0) {
      initializeTocItemsForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anticipatedAcuteLOS, transitionOfCareItems]);

  useEffect(() => {
    if (
      (intakeType === IntakeTypeEnum.NEW &&
        isHighRisk &&
        carePlanData.length === 0 &&
        !transitionOfCareItems &&
        pacTypes &&
        pacTypes.length > 0) ||
      (intakeType === IntakeTypeEnum.OLD &&
        status === TocStatusEnum.NEW &&
        !transitionOfCareItems &&
        carePlanData.length === 0 &&
        pacTypes &&
        pacTypes.length > 0) ||
      (customiseCarePlan && !transitionOfCareItems && carePlanData.length === 0 && pacTypes && pacTypes.length > 0)
    ) {
      dispatch(setIsCustomisedCarePlanEnabled(true));
      dispatch(
        setCarePlanData([
          {
            longName: "Episode",
            id: "-1",
            pacTypeName: "Episode",
            providerId: intake && intake.facilityId ? intake?.facilityId : -1,
            daysType: "day(s)",
            quantity: anticipatedAcuteLOS,
            targetLos: anticipatedAcuteLOS,
            admission: "after previous",
            pacTypeId: pacTypes.find((pacType) => pacType.itemShortName.toLowerCase() === "episode")?.id as number,
          },
        ])
      );
    }
  }, [
    dispatch,
    intake,
    intakeType,
    isHighRisk,
    pacTypes,
    anticipatedAcuteLOS,
    transitionOfCareItems,
    status,
    customiseCarePlan,
    carePlanData.length,
  ]);

  useEffect(() => {
    isIntakeOldOrNew();
  }, [isIntakeOldOrNew]);

  useEffect(() => {
    if (
      carePlanData &&
      carePlanData.length > 0 &&
      carePlanData.findIndex((carePlan) => carePlan.longName === CarePlanServices.EPISODE) !== -1 &&
      facilities.length > 0 &&
      allFacilities.length > 0
    ) {
      const providerId = carePlanData.find(
        (carePlanItem) => carePlanItem.longName === CarePlanServices.EPISODE
      )?.providerId;
      if (providerId !== undefined && providerId) {
        const provider = allFacilities.find((facility) => facility.id.toString() === providerId.toString());
        const ifProviderExists = facilities.findIndex((facility) => facility.id.toString() === provider?.id.toString());
        if (ifProviderExists === -1 && provider) {
          const copyOfAchFacilities = [...facilities];
          copyOfAchFacilities.push({ ...provider, isPrivileged: false });
          dispatch(updateAchFacilites(copyOfAchFacilities));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carePlanData, facilities, allFacilities]);

  useEffect(() => {
    if (toc && transitionOfCareItems && transitionOfCareItems.length > 0 && carePlans && carePlans.length > 0) {
      const defaultCarePlan = carePlans.find(
        (carePlan) => toc.standardCarePlanID && toc.standardCarePlanID.toString() === carePlan.id.toString()
      );
      if (defaultCarePlan) {
        setSelectedCarePlan({ name: defaultCarePlan?.label, key: defaultCarePlan?.id.toString() });
      }
    }
  }, [toc, carePlans, transitionOfCareItems]);

  useEffect(() => {
    if (!isEmptyString(copyOfSelectedCarePlan) && copyOfSelectedCarePlan === "-1") {
      const defaultCarePlan = carePlans.find(
        (carePlan) => toc.standardCarePlanID && toc.standardCarePlanID.toString() === carePlan.id.toString()
      );
      if (defaultCarePlan) {
        setSelectedCarePlan({ name: defaultCarePlan?.label, key: defaultCarePlan?.id.toString() });
      }
    } else if (!isEmptyString(copyOfSelectedCarePlan) && copyOfSelectedCarePlan === "-2") {
      setSelectedCarePlan({ name: "", key: "" });
    }
  }, [copyOfSelectedCarePlan, carePlans, toc.standardCarePlanID]);

  const onNavigatorNotesChange = (val: string) => {
    dispatch(setIsTocValueChanged(true));
    dispatch(setTocEditForm({ ...tocEditForm, navigatorNotes: val, isTocItemsFormDirty: true, isFormEmpty: false }));
  };

  const onAdditionalNotesChange = (val: string) => {
    dispatch(setIsTocValueChanged(true));
    dispatch(setTocEditForm({ ...tocEditForm, additionalNotes: val, isTocItemsFormDirty: true, isFormEmpty: false }));
  };

  const handleHomeWithoutNoServices = () => {
    dispatch(resetLocationValidation());
    dispatch(setIsTocValueChanged(true));
    dispatch(
      setTocEditForm({
        ...tocEditForm,
        isHomeWithNoServicesEnabled: !tocEditForm.isHomeWithNoServicesEnabled,
        isTocItemsFormDirty: true,
        isFormEmpty: false,
      })
    );
  };

  const checkIfNoteValid = () => {
    if (tocEditForm.navigatorNotes) {
      return tocEditForm.navigatorNotes.length <= NOTE_LIMIT;
    }
    return true;
  };

  const checkIfTocItemsValid = () => {
    let isValid;
    if (!tocEditForm.isHomeWithNoServicesEnabled) {
      isValid = carePlanData.every((carePlan) => carePlan.providerId !== null && parseInt(carePlan.providerId) > 0);
    } else {
      const episode = carePlanData.find((carePlan) => carePlan.longName === CarePlanServices.EPISODE)!;
      isValid = episode.providerId !== null && parseInt(episode.providerId) > 0;
    }

    return isValid;
  };

  const checkIfMandatoryTocItemsSelected = () => {
    return carePlanData.length > 1 || tocEditForm.isHomeWithNoServicesEnabled;
  };

  const getFacilityError = (isTocItemsValid: boolean, isValidToc: boolean) => {
    if (!isTocItemsValid) {
      return FACILITY_ERROR;
    }
    if (!isValidToc) {
      return FACILITY_AND_HOME_ERROR;
    }
    return "";
  };
  const checkMandatoryValues = () => {
    const los = carePlanData.find((i) => i.longName === CarePlanServices.EPISODE)?.quantity;
    const isActuteLosValid = los !== undefined && los > 0 ? true : false;
    const isValidToc = checkIfMandatoryTocItemsSelected();
    const isNoteValid = checkIfNoteValid();
    const isTocItemsValid = checkIfTocItemsValid();

    dispatch(
      setTocEditForm({
        ...tocEditForm,
        facilityError: getFacilityError(isTocItemsValid, isValidToc),
        losError: !isActuteLosValid ? LOS_ERROR : "",
      })
    );

    return isNoteValid && isActuteLosValid && isTocItemsValid && isValidToc;
  };

  const handleCarePlanChange = (option: { name: string; key: string }) => {
    dispatch(setTocEditForm({ ...tocEditForm, isTocItemsFormDirty: true, facilityError: "", losError: "" }));
    setSelectedCarePlan(option);
    handleSelectedCarePlan(option.key);
    dispatch(getServiceTemplateData({ standardCarePlanId: option.key, intakeId }));
  };

  useEffect(() => {
    if (isExpanded && !!approvedAt && isModified && !history) {
      dispatch(getTocHistory(id));
    }
  }, [id, isExpanded, approvedAt, isModified, history, dispatch]);

  const handleTocExpand = useCallback(() => {
    const tocId = id;
    const newTocs: ITransitionOfCare[] = [];
    if (toc.approvedAt) {
      tocs.forEach((tocItem) => {
        const updatedItem = { ...tocItem };
        if (tocItem.approvedAt) {
          if (tocItem.id === tocId) {
            updatedItem.isExpanded = !tocItem.isExpanded;
          } else {
            updatedItem.isExpanded = false;
          }
        }

        newTocs.push(updatedItem as ITransitionOfCare);
      });
    } else {
      tocs.forEach((tocItem) => {
        const updatedItem = { ...tocItem };
        if (!tocItem.approvedAt) {
          if (tocItem.id === tocId) {
            updatedItem.isExpanded = !tocItem.isExpanded;
          }
        }

        newTocs.push(updatedItem as ITransitionOfCare);
      });
    }
    dispatch(updateTocList(newTocs));
  }, [dispatch, id, tocs, toc.approvedAt]);

  const getRevisionReseaon = () => {
    return revisionReasons.find((el) => el.id === reasonId)?.reason;
  };

  const getCustomiseReason = () => {
    return customiseReasons.find((reason) => reason.id === customizeReasonId)?.name;
  };

  const isFacilityPriviliged = function (item: ITransitionOfCareItems) {
    if (item.pacTypeName === CarePlanServices.EPISODE) {
      const isPriviliged = facilities.find((facility) => item.providerId.toString() === facility.id.toString());
      return isPriviliged && isPriviliged !== undefined ? true : false;
    }

    return false;
  };

  const isPrefferedFacility = (item: ITransitionOfCareItems) => {
    const selectedFacilties = item.pacTypeName === CarePlanServices.EPISODE ? facilities : allFacilities;
    return selectedFacilties.find((facility) => item.providerId.toString() === facility.id.toString())
      ?.preferredProvider;
  };

  const onClickCustomiseCarePlan = () => {
    if (!isHighRisk) {
      dispatch(setIsOpenTocReasonModal(true));
    }
  };

  const onClickAddService = () => {
    dispatch(setIsOpenAddServiceModal({ isOpen: true, isEdit: false }));
  };

  const getDisableStatusForHomeWithoutServices = () => {
    if (customiseCarePlan && !isHighRisk) {
      return false;
    }
    if (isCustomised) {
      return false;
    }
    if (isHighRisk || intakeType === IntakeTypeEnum.OLD) {
      return false;
    }
    return true;
  };

  const isPageDirty = (tab: ITab) => {
    const page = pages.find((el) => el.taskId === tab.taskId);
    if (!page) {
      return false;
    }
    return page.isDirty;
  };

  const shouldErrorMessageDisplay = () => {
    const episode = carePlanData.find((carePlan) => carePlan.longName === CarePlanServices.EPISODE);
    const isAllProviderPresent = carePlanData.every(
      (carePlan) => carePlan.providerId !== null && parseInt(carePlan.providerId) > 0
    );
    if (tocEditForm.isHomeWithNoServicesEnabled && episode) {
      return !(episode.providerId !== null && parseInt(episode.providerId) > 0);
    } else if (!tocEditForm.isHomeWithNoServicesEnabled && isAllProviderPresent && carePlanData.length > 1) {
      return false;
    }

    return true;
  };

  const renderTocDetailsInReadMode = () => {
    const homeWithNoServices = getTocItem(PAC_TYPE.HOME_SERVICE);
    return (
      <>
        <div className={`toc-approve-info-readonly `}>
          <div className="toc-content">
            <div className="toc-plan">
              <div className="episode-details">
                <div className="toc-heading">
                  <div className="toc-plan-heading">Care Plan</div>
                  <div className="toc-plan-los-container">
                    <div className="acute-los-heading">Acute LOS</div>
                    <div className="acute-los-heading-sep">-</div>
                    <div className="acute-los-readonly">{anticipatedAcuteLOS.toString()}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="location-grid-container">
              <div className="location-dropdowns">
                <div className="location-row-header">
                  <div className="location">Services</div>
                  <div className="location-name">
                    <div>Provider</div>
                  </div>
                  <div className="los">LOS/Visits</div>
                </div>
                <div>
                  {transitionOfCareItems &&
                    transitionOfCareItems.length > 0 &&
                    transitionOfCareItems
                      .filter((careItem) => careItem.pacTypeName !== PAC_TYPE.HOME_SERVICE)
                      .map((careItem) => {
                        return (
                          <div className="location-row">
                            <div className="location-value">{careItem.pacTypeName}</div>
                            <div className="toc-dropdown-readonly">
                              {getProviderName(careItem.providerId)}
                              {isPrefferedFacility(careItem) && (
                                <span>
                                  <Icon icon="preferred" size={16} />
                                </span>
                              )}
                              {isFacilityPriviliged(careItem) && (
                                <span>
                                  <Icon icon="privileged" size={16} />
                                </span>
                              )}
                            </div>
                            <div className="toc-visits-readonly">{getTargetLos(careItem.targetLos)}</div>
                          </div>
                        );
                      })}

                  <div className="read-only-footer">
                    <div className="preferred-container">
                      <Icon icon="preferred" size={16} />
                      <div className="preferred-text">Preferred</div>
                      <Icon icon="privileged" size={16} />
                      <div className="preferred-text">Privileged</div>
                    </div>
                  </div>
                </div>
              </div>

              {homeWithNoServices ? (
                <div className="checkbox-container">
                  <div className="checkboxes" key="Incoming-checkox">
                    <Checkbox
                      name="homeWithoutServices"
                      value="Home w/No services"
                      id="Incoming-checkox"
                      onChange={() => {}}
                      isDisabled
                      isChecked={true}
                    />
                  </div>
                </div>
              ) : null}

              {!!approvedAt && isModified ? (
                <div className="view-changes-container">
                  <span
                    className="cta"
                    onClick={() => dispatch(setTocEditForm({ ...tocEditForm, isChangeModalOpen: true }))}
                  >
                    View Changes
                  </span>
                </div>
              ) : null}
            </div>
          </div>

          <div className="note-container">
            <div className="revision-reasons">
              {reasonId && (
                <div className="revision-reason">
                  <div className="note-heading">Reason for revising TOC</div>
                  <div className="value">{getRevisionReseaon()}</div>
                </div>
              )}
              {customizeReasonId && (
                <div className="revision-reason">
                  <div className="note-heading">Reason for Customizing Care Plan</div>
                  <div className="value">{getCustomiseReason()}</div>
                </div>
              )}
            </div>
            <div className="external-notes">
              <div className="navigator-notes">
                <div className="note-heading">Navigator Notes</div>
                <div className="note-textarea-readonly">
                  {!!noteCareManager && noteCareManager.length ? noteCareManager : "-"}
                </div>
              </div>
              {approvedAt ? (
                <div className="physician-notes">
                  <div className="note-heading">Physician Notes</div>
                  <div className="note-textarea-readonly">
                    {!!notePhysician && notePhysician.length ? notePhysician : "-"}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="internal-notes">
              <div className="note-heading">Additional Notes {"(Internal)"}</div>
              <div className="note-textarea-readonly">
                {!!additionalNote && additionalNote.length ? additionalNote : "-"}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderTocDetailsInEditMode = () => {
    return (
      <>
        <div className={`toc-approve-info `}>
          <div className="toc-plan">
            <div className="episode-details">
              <div className="toc-heading">
                <div className="toc-plan-heading">Care Plan</div>
                {!isHighRisk && intakeType === IntakeTypeEnum.NEW && !isCustomised && (
                  <div className="customize-care-plan">
                    <Button
                      className="white-button customise-care-plan-button"
                      text="Customize Care Plan"
                      onClick={onClickCustomiseCarePlan}
                      disabled={customiseCarePlan}
                    />
                  </div>
                )}
              </div>
              <div className="toc-plan-los-container"></div>
            </div>
            <div className="toc-add-service-with-options">
              <div className="toc-plan-options">
                {((!isHighRisk && intakeType === IntakeTypeEnum.NEW && !isCustomised) ||
                  (intakeType === IntakeTypeEnum.OLD &&
                    status === TocStatusEnum.NEW &&
                    !transitionOfCareItems &&
                    carePlanData.length === 0)) && (
                  <>
                    <div className="options-heading">Options</div>
                    <SingleSelectDropdownWithClearOption
                      placeholder="Select"
                      searchPlaceholder=""
                      onItemSelected={(option: any) => {
                        handleCarePlanChange(option);
                      }}
                      dropDownMenuItems={
                        carePlans && carePlans.length > 0
                          ? carePlans.map((carePlan: CarePlan) => {
                              return {
                                name: carePlan.label,
                                key: carePlan.id.toString(),
                              };
                            })
                          : []
                      }
                      value={{ name: selectedCarePlan.name, key: selectedCarePlan.key.toString() }}
                      clearSelection={() => {
                        handleCarePlanChange({ key: "", name: "" });
                        dispatch(resetCarePlan());
                      }}
                      noSearchBar={true}
                      dropDownBoxClass="care-plan-options-dropdown"
                    />
                  </>
                )}
              </div>
              <div className="add-service">
                {(customiseCarePlan || isHighRisk || intakeType === IntakeTypeEnum.OLD || isCustomised) && (
                  <Button
                    className="add-service-button white-button"
                    text="Add Service"
                    onClick={onClickAddService}
                    disabled={tocEditForm.isHomeWithNoServicesEnabled}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="note-container">
            <div className="revision-reasons">
              {reasonId && (
                <div className="revision-reason">
                  <div className="note-heading">Reason for revising TOC</div>
                  <div className="value">{getRevisionReseaon()}</div>
                </div>
              )}
              {customizeReasonId && (
                <div className="revision-reason">
                  <div className="note-heading">Reason for Customizing Care Plan</div>
                  <div className="value">{getCustomiseReason()}</div>
                </div>
              )}
            </div>
            <div className="navigator-notes">
              <div className="note-heading">Navigator Notes</div>
              <textarea
                className="note-textarea"
                value={tocEditForm.navigatorNotes ?? ""}
                onChange={(e: any) => {
                  onNavigatorNotesChange(e.target.value);
                }}
                maxLength={2000}
                placeholder="Write your notes here for the physician..."
                cols={5}
              />
              {tocEditForm.navigatorNotes && tocEditForm.navigatorNotes.length > 0 && (
                <div className="note-hint">
                  <div className="note-limit">{`${tocEditForm.navigatorNotes.length}/${2000}`}</div>
                </div>
              )}
            </div>
            <div className="additinal-notes">
              <div className="note-heading">Additional Notes {"(Internal)"}</div>
              <textarea
                className="note-textarea"
                value={tocEditForm.additionalNotes ?? ""}
                onChange={(e: any) => {
                  onAdditionalNotesChange(e.target.value);
                }}
                maxLength={2000}
                placeholder="Write your notes here for internal use..."
                cols={5}
              />
              {tocEditForm.additionalNotes && tocEditForm.additionalNotes?.length > 0 && (
                <div className="note-hint">
                  <div className="note-limit">{`${tocEditForm.additionalNotes?.length}/${2000}`}</div>
                </div>
              )}
            </div>
          </div>
          <div className="location-grid-container">
            <div className="location-dropdowns">
              {loading && <CommonState type={CommonStateType.LOADING} />}
              {!loading && (
                <DndProvider backend={HTML5Backend}>
                  <SortableList selectedCarePlan={selectedCarePlan} toc={toc} intakeType={intakeType} />
                </DndProvider>
              )}
            </div>
            {tocEditForm.facilityError &&
              shouldErrorMessageDisplay() &&
              (isCustomised || customiseCarePlan || !isEmptyString(selectedCarePlan.name)) && (
                <div className="toc-plan-error">
                  <span>
                    <img src={ErrorIcon} alt="Error" />
                    {tocEditForm.facilityError}
                  </span>
                </div>
              )}
            {tocEditForm.losError && (isCustomised || customiseCarePlan || !isEmptyString(selectedCarePlan.name)) && (
              <div className="toc-plan-error">
                <span>
                  <img src={ErrorIcon} alt="Error" />
                  {tocEditForm.losError}
                </span>
              </div>
            )}
            {(tocEditForm.facilityError || tocEditForm.losError) &&
              !isCustomised &&
              !customiseCarePlan &&
              isEmptyString(selectedCarePlan.name) && (
                <div className="toc-plan-error">
                  <span>
                    <img src={ErrorIcon} alt="Error" />
                    Select an option first
                  </span>
                </div>
              )}
            <div className="checkbox-container">
              <div className="checkboxes" key="Incoming-checkox">
                <Checkbox
                  name="homeWithoutServices"
                  value="Home w/No services"
                  id="Incoming-checkox"
                  onChange={handleHomeWithoutNoServices}
                  isChecked={tocEditForm.isHomeWithNoServicesEnabled}
                  isDisabled={getDisableStatusForHomeWithoutServices()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="last-row-with-approve-button">
          <div className="cta-container">
            {status === TocStatusEnum.PENDING || status === TocStatusEnum.NEW ? (
              <Button
                text={"Cancel"}
                className={`white-button cancel-button`}
                showLoader={false}
                disabled={status === TocStatusEnum.PENDING ? !tocEditForm.isTocItemsFormDirty : false}
                onClick={() => {
                  handleSelectedToc(toc);
                  if (isPageDirty(currentTab!) && version > 1) {
                    dispatch(setShowUnsavedChangesModal(true));
                    return;
                  }
                  if (version === 1 || !isEmptyString(toc.id)) {
                    dispatch(setTocEditForm({ ...tocEditForm, isCancelModalOpen: true }));
                  } else {
                    if (tocs[toc.version - (toc.version - 1)].originalStatus === TocStatusEnum.PENDING) {
                      dispatch(changeTocStatus(toc.version - (toc.version - 1)));
                    }
                    dispatch(deleteToc(toc.version));
                  }
                }}
              />
            ) : null}
            <Button
              text="Submit"
              className={`green-button submit-button `}
              showLoader={false}
              disabled={!tocEditForm.isTocItemsFormDirty}
              onClick={() => {
                setIsSubmitButtonPressed(true);
                handleSelectedToc(toc);
                handleSelectedCarePlan(selectedCarePlan.key);
                if (checkMandatoryValues()) {
                  dispatch(setTocEditForm({ ...tocEditForm, isSubmitModalOpen: true }));
                }
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const renderRevisions = () => {
    if (approvedAt) {
      return <>{renderTocDetailsInReadMode()}</>;
    } else if (status === TocStatusEnum.PENDING || status === TocStatusEnum.NEW) {
      return <>{renderTocDetailsInEditMode()}</>;
    }
    return <>{renderTocDetailsInReadMode()}</>;
  };

  return (
    <>
      {tocEditForm.isChangeModalOpen && !!history ? (
        <TocChangeModal
          isOpen={tocEditForm.isChangeModalOpen}
          facilities={allFacilities}
          onClose={(isOpen) => dispatch(setTocEditForm({ ...tocEditForm, isChangeModalOpen: isOpen }))}
          historyOfToc={history}
          toc={toc}
        />
      ) : null}
      <div
        onMouseEnter={() => setIsRowHovered(true)}
        onMouseLeave={() => setIsRowHovered(false)}
        className={`toc-row ${isRowHovered ? "hovered" : ""} ${isExpanded ? "opened" : ""}`}
      >
        <div className="toc-cell">{version === 1 ? "Original Care Plan" : `Revision ${version - 1}`}</div>
        {status === TocStatusEnum.NEW ? (
          <div className="toc-cell">{"-"}</div>
        ) : (
          <div className="toc-cell">
            <div className="created-date">{moment(createdAt).format("MM/DD/YYYY : hh:mm A")} </div>
            <div className="created-by">{createdBy ? "by " + createdBy : "-"}</div>
          </div>
        )}
        <div className={`toc-cell ${status === TocStatusEnum.PENDING ? "pending-status" : null}`}>{status}</div>
        {approvedAt ? (
          <div className="toc-cell">
            <div className="approved-date">{moment(approvedAt).format("MM/DD/YYYY : hh:mm A")} </div>
            <div className="approved-by">{"by " + (approvedBy ? approvedBy : "-")}</div>
          </div>
        ) : (
          <div className="toc-cell">{"-"}</div>
        )}
        <div className={`toc-cell icons ${isRowHovered || isExpanded ? "visible" : "hidden"}`}>
          {isExpanded ? (
            <Button
              icon="up-arrow"
              onClick={() => {
                handleTocExpand();
              }}
              buttonSize={10}
              className="icon-background"
            />
          ) : (
            <Button
              icon="down-arrow"
              onClick={() => {
                handleTocExpand();
              }}
              buttonSize={10}
              className="icon-background"
            />
          )}
        </div>
      </div>
      {isExpanded ? <>{renderRevisions()}</> : null}
    </>
  );
};

export default TocRevision;
