export const services = [
  {
    key: 1,
    name: "IRF",
  },
  {
    key: 2,
    name: "SNF",
  },
  {
    key: 3,
    name: "HH",
  },
  {
    key: 4,
    name: "OPT",
  },
  {
    key: 5,
    name: "M-PT",
  },
];

export const editService = "Edit Service";
export const addService = "Add Service";
export const saveText = "Save";
export const addText = "Add";

export enum CarePlan {
  IRF = "IRF",
  SNF = "SNF",
  OPT = "OPT",
  MPT = "M-PT",
  HH = "HH",
}

export const visits = "visit(s)";
export const days = "day(s)";
