import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { getBrowserState, getCurrentPageTaskState } from "state/feature/task-management/task-management.slice";
import {
  findDistance,
  getAchFacilities,
  getAllTocFacilitiesAsync,
} from "state/feature/toc-management/toc-management.action";
import {
  getTOCManagementState,
  setAchDistance,
  setAllFacilitiesDistance,
} from "state/feature/toc-management/toc-management.slice";
import { useAppDispatch } from "state/store";

const useFacilities = (intakeId: string) => {
  const dispatch = useAppDispatch();
  const { currentTab } = useSelector(getBrowserState);
  const { patientDetails } = useSelector(getCurrentPageTaskState(currentTab!.taskId));
  const { facilities, physicianId, allFacilities, intake } = useSelector(getTOCManagementState);
  useEffect(() => {
    if (intakeId && !facilities.length && physicianId && intake && intake.clientId) {
      dispatch(getAchFacilities({ surgeonId: physicianId, clientId: intake.clientId }));
    }
    if (intakeId && !allFacilities.length) {
      dispatch(getAllTocFacilitiesAsync(intakeId));
    }
  }, [dispatch, intakeId, physicianId, facilities.length, allFacilities, intake?.clientId, intake]);

  const getPatientLocation = useCallback(() => {
    const location = [];

    if (patientDetails?.patientAddress.address1) {
      location.push(patientDetails?.patientAddress.address1);
    }

    if (patientDetails?.patientAddress.city) {
      location.push(patientDetails?.patientAddress.city);
    }

    if (patientDetails?.patientAddress.state) {
      location.push(patientDetails?.patientAddress.state);
    }

    if (location.length) {
      return [location.join(", ")];
    }
    return [];
  }, [patientDetails]);

  const getAchFacilitiesWithDistance = useCallback(async () => {
    if (facilities.length) {
      const selectedPlaces: Array<{ id: number; address: string }> = [];
      const origins = getPatientLocation();
      const destinations = facilities
        .filter((el) => {
          let location = "";

          if (el.providerAddress1 && el.providerAddress1.length) {
            location += el.providerAddress1;
          }

          if (el.providerCity && el.providerCity.length) {
            location += el.providerCity;
          }

          if (el.providerState && el.providerState.length) {
            location += el.providerState;
          }

          return location.length;
        })
        .map((el) => {
          const location = [];

          if (el.providerAddress1 && el.providerAddress1.length) {
            location.push(el.providerAddress1);
          }

          if (el.providerCity && el.providerCity.length) {
            location.push(el.providerCity);
          }

          if (el.providerState && el.providerState.length) {
            location.push(el.providerState);
          }

          selectedPlaces.push({
            id: el.id,
            address: location.join(", "),
          });

          return location.join(", ");
        });

      if (origins.length && destinations.length && selectedPlaces.length) {
        dispatch(findDistance({ origins, destinations, selectedPlaces })).then((response) => {
          dispatch(
            setAchDistance({
              selectedPlaces,
              data: response.payload,
            })
          );
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilities.length, dispatch, getPatientLocation]);

  const getAllFacilitiesWithDistance = useCallback(async () => {
    if (allFacilities.length) {
      const selectedPlaces: Array<{ id: number; address: string }> = [];
      const origins = getPatientLocation();
      const destinations = allFacilities
        .filter((el) => {
          let location = "";

          if (el.providerAddress1 && el.providerAddress1.length) {
            location += el.providerAddress1;
          }

          if (el.providerCity && el.providerCity.length) {
            location += el.providerCity;
          }

          if (el.providerState && el.providerState.length) {
            location += el.providerState;
          }

          return location.length;
        })
        .map((el) => {
          const location = [];

          if (el.providerAddress1 && el.providerAddress1.length) {
            location.push(el.providerAddress1);
          }

          if (el.providerCity && el.providerCity.length) {
            location.push(el.providerCity);
          }

          if (el.providerState && el.providerState.length) {
            location.push(el.providerState);
          }

          selectedPlaces.push({
            id: el.id,
            address: location.join(", "),
          });

          return location.join(", ");
        });

      if (origins.length && destinations.length && selectedPlaces.length) {
        dispatch(findDistance({ origins, destinations, selectedPlaces })).then((response) => {
          dispatch(
            setAllFacilitiesDistance({
              selectedPlaces,
              data: response.payload,
            })
          );
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilities.length, dispatch, getPatientLocation]);

  return { getAchFacilitiesWithDistance, getAllFacilitiesWithDistance };
};

export default useFacilities;
