import { useDispatch, useSelector } from "react-redux";
import { getCommonState } from "state/feature/common/common.slice";
import Modal from "../modal.component";
import { tocReasonDropdownHeading, tocReasonHeading, tocReasonSubHeading } from "./toc-reason-modal.constant";
import CustomDropDown from "components/CustomDropdown/custom-dropdown.component";
import Button from "components/Button/button.component";
import { TocReasonModalPresentationProps } from "./toc-reason-modal.props";
import { getTOCManagementState, setTocCustomiseReason } from "state/feature/toc-management/toc-management.slice";

const TocReasonModalPresentation = (props: TocReasonModalPresentationProps) => {
  const { modal } = useSelector(getCommonState);
  const { tocCustomiseReason, customiseReasons } = useSelector(getTOCManagementState);
  const dispatch = useDispatch();
  const { closeTocReasonModal, handleSubmit, showError, handleCustomiseError } = props;
  return (
    <Modal isOpen={modal.isOpenTocReasonModal} className="center toc-reason-modal">
      <div className="header">{tocReasonHeading}</div>
      <div className="toc-reason-content">
        <div className="toc-reason-content-sub-heading">{tocReasonSubHeading}</div>
      </div>
      <div className="heading">{tocReasonDropdownHeading}</div>
      <CustomDropDown
        placeholder="Select"
        dropDownMenuItems={customiseReasons.map((item) => {
          return {
            ...item,
          };
        })}
        handleValueChanges={(option: any) => {
          handleCustomiseError(false);
          dispatch(setTocCustomiseReason({ id: option.id, name: option.name }));
        }}
        value={{ name: tocCustomiseReason.name, key: tocCustomiseReason.id }}
        idFieldName="key"
        dropDownBoxClass="result-dropdown"
        selectionClass="result-selection"
        dropDownContainerClass={`reason-dropdown-container`}
        isDisabled={false}
        isError={showError}
        message="Select a reason"
      />
      <div className="button-section">
        <div>
          <Button text="Cancel" className="white-button cancel-button" onClick={closeTocReasonModal} />
          <Button text="Submit" className="green-button submit-button" onClick={handleSubmit} />
        </div>
      </div>
    </Modal>
  );
};

export default TocReasonModalPresentation;
