import Avatar from "components/Avatar/avatar.component";
import Icon from "components/Icon/icon.component";
import { useContext, useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAgeFromDate, getDateInMMDDYYYYFormat } from "shared/methods/utilityFunctions";
import { getCallState } from "state/feature/call/call.slice";
import { setIsOpenNumberSelectorModal } from "state/feature/common/common.slice";
import { getEpisodesSlice } from "state/feature/episodes/episodes.slice";
import { PatientDetailsProps } from "pages/episodes/types";
import { CustomToolTip } from "components/CustomTooltip/custom-tooltip.component";
import PatientPhoneNumbersDropdown from "components/PatientPhoneNumbersDropdown/patient-phone-numbers-dropdown.component";
import { PatientRegisteredTooltip } from "components/patient-registered-tooltip/patient-registered-tooltip.component";
import { CallingStatus } from "shared/enums/calling-status.enum";
import { CallContext } from "App";

const PatientDetailsPresentation = (props: PatientDetailsProps) => {
  const { makeACall, handleCall, handleMessage } = props;
  const { patientEpisodeDetails, episodeDetails } = useSelector(getEpisodesSlice);
  const { ongoingCall: isOngoingCall } = useContext(CallContext);
  const [showPhoneNumbers, setShowPhoneNumbers] = useState(false);
  const callState = useSelector(getCallState);
  const [isCallButtonClicked, setIsCallButtonClicked] = useState(false);
  const dispatch = useDispatch();
  const setShowPhoneNumbersFunction = (val: boolean) => {
    setShowPhoneNumbers(val);
  };

  const setIsCallButtonClickedFunction = (val: boolean) => {
    setIsCallButtonClicked(val);
  };

  const handlePhoneDropdown = (phoneNumber: string) => {
    if (!callState.currentCall && patientEpisodeDetails) {
      makeACall({
        phoneNumber: phoneNumber.trim(),
        clientId: patientEpisodeDetails?.clientId,
        isCallButtonClicked,
        patientName: patientEpisodeDetails.patientName,
        patientUserId: patientEpisodeDetails.patientUserId,
        setIsCallButtonClickedFunction,
        setShowPhoneNumbersFunction,
      });
      dispatch(setIsOpenNumberSelectorModal(false));
    } else {
      toast.error("Another call is ongoing", {
        toastId: "call-ongoing",
        containerId: "main",
      });
    }
  };

  const renderPatientDetails = () => {
    if (patientEpisodeDetails) {
      const {
        admitDate,
        dob,
        episodeName,
        episodeSide,
        episodeSite,
        gender,
        isOffTrack,
        physicianName,
        programDischargeDate,
        programName,
        riskValue,
        status,
        surgeryDate,
        surgeryDischargeDate,
      } = patientEpisodeDetails;
      return (
        <>
          <div className="episode-patient-details-right-columns">
            <div className="episode-patient-details-right-content">
              <div className="name">DOB | Age | Gender</div>
              <div className="value">
                {dob ? `${getDateInMMDDYYYYFormat(new Date(dob))} | ${getAgeFromDate(new Date(dob))} | ${gender}` : "-"}
              </div>
            </div>
            <div className="episode-patient-details-right-content">
              <div className="name">Status</div>
              <div className="value">{status}</div>
            </div>
            <div className="episode-patient-details-right-content">
              <div className="name">Risk</div>
              <div className={`value ${riskValue && riskValue.toLowerCase().includes("high") ? "red-text" : ""}`}>
                {riskValue}
              </div>
            </div>
            <div className="episode-patient-details-right-content">
              <div className="name">Care Plan Adherence</div>
              <div className={`value ${isOffTrack === "Off-Track" ? "red-text" : ""}`}>{isOffTrack}</div>
            </div>
          </div>
          <div className="episode-patient-details-right-columns">
            <div className="episode-patient-details-right-content">
              <div className="name">Program</div>
              <div className="value">{programName}</div>
            </div>
            <div className="episode-patient-details-right-content">
              <div className="name">Physician</div>
              <div className="value">{physicianName}</div>
            </div>
            <div className="episode-patient-details-right-content">
              <div className="name">
                Episode
                <div>(Site-Side)</div>
              </div>
              <div className="value">{`${episodeName} (${episodeSite} - ${episodeSide})`}</div>
            </div>
          </div>
          <div className="episode-patient-details-right-columns">
            <div className="episode-patient-details-right-content">
              <div className="name">Prog. Discharge Date</div>
              <div className="value">
                {programDischargeDate ? getDateInMMDDYYYYFormat(new Date(programDischargeDate)) : "-"}
              </div>
            </div>
            <div className="episode-patient-details-right-content">
              <div className="name">Admission</div>
              <div className="value">{admitDate ? getDateInMMDDYYYYFormat(new Date(admitDate)) : "-"}</div>
            </div>
            <div className="episode-patient-details-right-content">
              <div className="name">Surgery</div>
              <div className="value">{surgeryDate ? getDateInMMDDYYYYFormat(new Date(surgeryDate)) : "-"}</div>
            </div>
            <div className="episode-patient-details-right-content">
              <div className="name">Discharge</div>
              <div className="value">
                {surgeryDischargeDate ? getDateInMMDDYYYYFormat(new Date(surgeryDischargeDate)) : "-"}
              </div>
            </div>
          </div>
        </>
      );
    }
    return "";
  };
  return (
    <div className="episode-patient-details">
      <div className="episode-patient-details-left">
        <div className="episode-patient-details-avatar">
          <Avatar callIconButton={true} personName={patientEpisodeDetails?.patientName} />
          <div className="call-icon">
            <div
              data-tip
              data-for={"Call"}
              className=" episode-patient-detail-icon"
              onClick={() => {
                if (
                  patientEpisodeDetails &&
                  !isOngoingCall &&
                  !(
                    localStorage.getItem("callStatus") === CallingStatus.CONNECTED ||
                    localStorage.getItem("callStatus") === CallingStatus.RINGING
                  )
                ) {
                  handleCall({
                    clientId: patientEpisodeDetails?.clientId,
                    contactDetails: patientEpisodeDetails.contactDetails,
                    isCallButtonClicked,
                    patientName: patientEpisodeDetails.patientName,
                    patientPhoneNumber: patientEpisodeDetails.patientPhoneNumber,
                    patientUserId: patientEpisodeDetails?.patientUserId,
                    setIsCallButtonClickedFunction,
                    setShowPhoneNumbersFunction,
                  });
                }
              }}
            >
              <CustomToolTip text={"Call"} id="Call" place="bottom" />
              <Icon icon="call-icon" size={20} />
            </div>
            {showPhoneNumbers && (
              <ClickAwayListener
                onClickAway={() => {
                  setShowPhoneNumbers(false);
                }}
              >
                <PatientPhoneNumbersDropdown
                  patient={patientEpisodeDetails?.patientName ?? ""}
                  id={patientEpisodeDetails?.patientId?.toString() ?? ""}
                  contactDetails={patientEpisodeDetails?.contactDetails ?? []}
                  handleClick={handlePhoneDropdown}
                  key={patientEpisodeDetails?.patientId}
                  className="down"
                />
              </ClickAwayListener>
            )}
          </div>
          <div className={`message-icon`} onClick={handleMessage} data-tip data-for={"Message"}>
            <Icon icon="navigator-message" size={17} />
            <CustomToolTip text={"Message"} id="Message" place="bottom" />
          </div>
        </div>
        <div className="episode-patient-details-left-name-container">
          <div className="episode-patient-details-left-name">{patientEpisodeDetails?.patientName}</div>
          {episodeDetails?.patientLastLoginAt && episodeDetails.patientLastLoginAt.isRegistered && (
            <div
              className="patient-registered-icon-container"
              data-tip
              data-for={`patient-registered-icon-${patientEpisodeDetails?.patientName}`}
            >
              <Icon icon="patient-registered" size={17} />
              <CustomToolTip
                id={`patient-registered-icon-${patientEpisodeDetails?.patientName}`}
                text={<PatientRegisteredTooltip patientLastLogged={episodeDetails?.patientLastLoginAt} />}
                place="bottom"
              />
            </div>
          )}
        </div>
      </div>
      <div className="episode-patient-details-right">{renderPatientDetails()}</div>
    </div>
  );
};

export default PatientDetailsPresentation;
