import { useState, useEffect } from "react";
import Icon from "components/Icon/icon.component";
import RowIcons from "components/RowIcons/row-icons.component";
import TooltipSingleLine from "components/TooltipSingleLine/tooltip-single-line.component";
import OngoingTag from "components/OngoingTag/ongoing-tag.component";
import { getDurationOfCall } from "shared/methods/utilityFunctions";
import { useSelector } from "react-redux";
import { acknowledgeMissedCall } from "state/feature/navigator/navigator.action";
import { getNavigatorState, setCallReadStatus } from "state/feature/navigator/navigator.slice";
import { setOpenParticipantsPopup } from "state/feature/call/call.slice";
import { isMobile } from "react-device-detect";
import SidePopup from "components/SidePopup/side-popup.component";
import InboundCallIcons from "components/inbound-call-icons/inbound-call-icons.component";
import { getCommonState } from "state/feature/common/common.slice";
import { getAuth } from "state/feature/auth/auth.slice";
import { USER_ROLE } from "shared/constant/role-constant";
import { useAppDispatch } from "state/store";
import { toast } from "react-toastify";
import { CALL_NOT_READ, CALL_READ } from "shared/constant/commonConstants";
import { CallingStatus } from "shared/enums/calling-status.enum";
import { Participant, TableNavigatorDashboardProps } from "shared/types/table.type";

export interface TableNavigatorDashboardAllProps extends TableNavigatorDashboardProps {
  index: number;
  indexOfAudioPlayer: number;
  setIndexOfAudioPlayer: (index: number) => void;
  clientID: string;
  isReplyButtonDisabled: boolean;
  originalRecipient?: any;
  reasonForPrimaryUnavailability: string | null;
  reasonForBackupUnavailability?: string | null;
  effectiveBackupNavigator?: string | null;
  openForceQuitCallModal?: (value: string, id: string, otherParticipant?: Participant) => void;
  callSid: string;
}

const CallLogRow = ({
  callStartTime,
  duration,
  direction,
  recordingUri,
  callStatus,
  participants,
  searchText,
  index,
  indexOfAudioPlayer,
  setIndexOfAudioPlayer,
  isOnCallNavigator,
  isBackupNavigator,
  receivedBy,
  participantDetailsStateData,
  currentTableData,
  clientID,
  isReplyButtonDisabled,
  originalRecipient,
  reasonForPrimaryUnavailability,
  effectiveNavigator,
  reasonForBackupUnavailability,
  effectiveBackupNavigator,
  openForceQuitCallModal,
  callSid,
}: TableNavigatorDashboardAllProps) => {
  const [isRowHovered, setIsRowHovered] = useState(false);
  const [isParticipantHovered, setIsParticipantHovered] = useState(false);
  const [isIcontHovered, setIsIconHovered] = useState(false);
  const authState = useSelector(getAuth);
  const { currentUserProfile, managerStats, calls } = useSelector(getNavigatorState);
  const { featureFlags } = useSelector(getCommonState);
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [callerParticipantDetails, setCallerParticipantDetails] = useState<Participant>();
  const { user } = useSelector(getAuth);

  const [otherParticipantDetails, setOtherParticipantDetails] = useState<Participant>();

  const handleSidePopupClose = () => {
    setIsOpen((prev) => {
      if (
        !prev &&
        !callerParticipantDetails?.isMissedCallRead &&
        callerParticipantDetails?.status === CallingStatus.MISSED
      ) {
        dispatch(
          acknowledgeMissedCall({
            participantId: callerParticipantDetails?.id,
            callId: calls[index].id,
          })
        ).then((response: any) => {
          if (response.error) {
            toast.error(CALL_NOT_READ, {
              toastId: "acknowledgement-error",
              containerId: "main",
            });
          } else {
            dispatch(setCallReadStatus({ index: index, participantDetail: callerParticipantDetails }));
            toast.success(CALL_READ, {
              toastId: "acknowledgement-sucess",
              containerId: "main",
            });
          }
        });
      }
      return !prev;
    });
  };

  const getReason = () => {
    if (reasonForPrimaryUnavailability) {
      return reasonForPrimaryUnavailability;
    }
    return "-";
  };

  useEffect(() => {
    const otherParticipant = participants.find(
      (obj) => obj.type.toLowerCase() !== USER_ROLE.NAVIGATOR.toLowerCase() && obj.isCaller === true
    );
    setOtherParticipantDetails(otherParticipant);

    if (authState.user.roles[0].toLowerCase() === USER_ROLE.NAVIGATOR.toLowerCase()) {
      if (currentUserProfile) {
        const participant: Participant[] = participants.filter((item) => item.id === currentUserProfile.id);
        setCallerParticipantDetails(participant[0]);
      }
    } else {
      if (managerStats.selectedNavigator) {
        const participant: Participant[] = participants.filter((item) => item.id === managerStats.selectedNavigator.id);
        setCallerParticipantDetails(participant[0]);
      }
    }
  }, [
    currentUserProfile,
    participants,
    featureFlags.inboundCalls,
    managerStats.selectedNavigator,
    authState.user.roles,
  ]);

  const isAtleastOnePatient = () => {
    const isAtleastOnePatientValue = participants.filter((item: any) => item.type === "Patient");
    return isAtleastOnePatientValue.length > 0;
  };

  const getParticpantTypeAndDetails = () => {
    return (
      <>
        {participants.filter((x) => x.type.toLowerCase() !== "navigator").length === 1 &&
          participants.filter((x) => x.type.toLowerCase() !== "navigator")[0].type !== null && (
            <div className="participant-type">
              {participants.filter((x) => x.type.toLowerCase() !== "navigator")[0].type}
            </div>
          )}
        {(isParticipantHovered || isRowHovered) && isAtleastOnePatient() && (
          <span
            className="participant-info-button"
            onClick={() => {
              dispatch(setOpenParticipantsPopup({ open: true, participants }));
            }}
          >
            <Icon icon="participant-info" size={20} />
          </span>
        )}
      </>
    );
  };

  return !isMobile ? (
    <tr
      onMouseEnter={() => setIsRowHovered(true)}
      onMouseUp={() => {
        setIsRowHovered(false);
      }}
      onMouseLeave={() => setIsRowHovered(false)}
      className={`${isRowHovered || index === indexOfAudioPlayer ? "hovered" : ""}  ${
        isOnCallNavigator &&
        callerParticipantDetails?.isMissedCallRead === false &&
        (callerParticipantDetails.status === CallingStatus.MISSED ||
          callerParticipantDetails?.status === CallingStatus.NO_ANSWER ||
          callerParticipantDetails?.status === CallingStatus.BUSY)
          ? "night-border"
          : ""
      } ${callerParticipantDetails?.status === "in-progress" ? "green-ongoing-color" : ""} ${
        isBackupNavigator ? "purple-border" : ""
      } ${
        callerParticipantDetails?.status === CallingStatus.IN_PROGRESS ||
        callerParticipantDetails?.status === CallingStatus.RINGING ||
        callerParticipantDetails?.status === CallingStatus.RECORDING_VOICEMAIL
          ? "green-ongoing-color"
          : ""
      } ${isBackupNavigator ? "purple-border" : ""} ${
        (callerParticipantDetails?.status === CallingStatus.MISSED ||
          callerParticipantDetails?.status === CallingStatus.NO_ANSWER ||
          callerParticipantDetails?.status === CallingStatus.BUSY) &&
        (callerParticipantDetails?.isMissedCallRead === false || !callerParticipantDetails?.isMissedCallRead)
          ? "not-read"
          : ""
      } ${
        (callerParticipantDetails?.status === CallingStatus.MISSED ||
          callerParticipantDetails?.status === CallingStatus.NO_ANSWER ||
          callerParticipantDetails?.status === CallingStatus.BUSY) &&
        callerParticipantDetails?.isMissedCallRead === true
          ? "missed"
          : ""
      }`}
    >
      <td>
        <InboundCallIcons participantDetail={callerParticipantDetails} />
      </td>
      <td onMouseEnter={() => setIsParticipantHovered(true)} onMouseLeave={() => setIsParticipantHovered(false)}>
        <div className="participants-section">
          <div className="participants-name">
            {participants
              .filter((x) => x.type.toLowerCase() !== "navigator")
              .map(
                (participant: any, participantIndex: number, newParticipants) =>
                  `${participant.id ? participant.name : participant.phoneNumber}${
                    participantIndex === newParticipants.length - 1 ? "" : ", "
                  }`
              )}
          </div>
          {authState.user.roles.includes(USER_ROLE.MANAGER) ? (
            <div className="participant-type-info-container">{getParticpantTypeAndDetails()}</div>
          ) : (
            getParticpantTypeAndDetails()
          )}
        </div>
      </td>
      <td>
        <div
          className="date-time-container"
          onMouseEnter={() => setIsIconHovered(true)}
          onMouseLeave={() => setIsIconHovered(false)}
        >
          {callStartTime}
          {isOnCallNavigator ? <Icon icon="OnCallNavigatorIcon" className="night-icon" size={13} /> : null}
          {isBackupNavigator ? <Icon icon="BackupNavigatorIcon" className="night-icon" size={13} /> : null}
          {isIcontHovered &&
            receivedBy &&
            ((isOnCallNavigator && !isBackupNavigator) || (!isOnCallNavigator && isBackupNavigator)) && (
              <TooltipSingleLine
                isOnCallNavigator={isOnCallNavigator}
                isBackupNavigator={isBackupNavigator}
                receivedBy={receivedBy}
                isHovered={true}
              />
            )}
        </div>
      </td>
      <td>
        {callerParticipantDetails?.status === CallingStatus.MISSED ||
        callerParticipantDetails?.status === CallingStatus.NO_ANSWER ||
        callerParticipantDetails?.status === CallingStatus.BUSY ||
        callerParticipantDetails?.status === CallingStatus.IN_PROGRESS ||
        callerParticipantDetails?.status === CallingStatus.RECORDING_VOICEMAIL
          ? "-"
          : getDurationOfCall(Number(duration))}
      </td>
      <td>
        {callerParticipantDetails?.isCaller && effectiveNavigator?.name
          ? effectiveNavigator.name
          : originalRecipient
          ? originalRecipient.name
          : "-"}
      </td>
      {user?.roles?.includes(USER_ROLE.MANAGER) && (
        <>
          <td>{getReason()}</td>
          <td>{effectiveBackupNavigator ? effectiveBackupNavigator : "-"}</td>
          <td>{reasonForBackupUnavailability && effectiveBackupNavigator ? reasonForBackupUnavailability : "-"}</td>
          <td>
            {(callerParticipantDetails?.status === CallingStatus.IN_PROGRESS ||
              callerParticipantDetails?.status === CallingStatus.RINGING ||
              callerParticipantDetails?.status === CallingStatus.RECORDING_VOICEMAIL) && (
              <div>
                <OngoingTag
                  status={callerParticipantDetails?.status}
                  isOutgoingCall={callerParticipantDetails.isCaller}
                  openForceQuitCallModal={() => {
                    openForceQuitCallModal?.(callerParticipantDetails.id, callSid, otherParticipantDetails);
                  }}
                  hideCallQuitIcon={
                    callerParticipantDetails?.id !== currentUserProfile?.id ||
                    callerParticipantDetails?.status === CallingStatus.RECORDING_VOICEMAIL
                  }
                />
              </div>
            )}
          </td>
          <td className={`rows-icon-visible`}>
            {(callerParticipantDetails?.status === CallingStatus.MISSED ||
              callerParticipantDetails?.status === CallingStatus.NO_ANSWER ||
              callerParticipantDetails?.status === CallingStatus.BUSY) &&
              ((callerParticipantDetails?.voiceMailUrl && callerParticipantDetails?.voiceMailUrl !== "") ||
                isReplyButtonDisabled) && (
                <div className={`action-table ${isRowHovered || index === indexOfAudioPlayer ? "visible" : "hidden"}`}>
                  <div className="action-row">
                    <div className="empty-filling"></div>
                    <div className="action-col">
                      <div className="row-icon-container icons">
                        <RowIcons
                          index={index}
                          indexOfAudioPlayer={indexOfAudioPlayer}
                          setIndexOfAudioPlayer={setIndexOfAudioPlayer}
                          recordingUri={recordingUri}
                          participants={participants}
                          clientID={clientID as string}
                          isReply={isReplyButtonDisabled}
                          voiceMailUrl={callerParticipantDetails?.voiceMailUrl}
                          callerParticipantDetails={callerParticipantDetails}
                          callStartTime={callStartTime}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
          </td>
        </>
      )}

      {!user.roles?.includes(USER_ROLE.MANAGER) && (
        <td className={index === indexOfAudioPlayer ? "no-padding" : ""}>
          {(callerParticipantDetails?.status === CallingStatus.MISSED ||
            callerParticipantDetails?.status === CallingStatus.NO_ANSWER ||
            callerParticipantDetails?.status === CallingStatus.BUSY) && (
            <div className={`icons ${isRowHovered || index === indexOfAudioPlayer ? "visible" : "hidden"}`}>
              <RowIcons
                index={index}
                indexOfAudioPlayer={indexOfAudioPlayer}
                setIndexOfAudioPlayer={setIndexOfAudioPlayer}
                recordingUri={recordingUri}
                participants={participants}
                clientID={clientID as string}
                isReply={isReplyButtonDisabled}
                voiceMailUrl={callerParticipantDetails?.voiceMailUrl}
                callerParticipantDetails={callerParticipantDetails}
                callStartTime={callStartTime}
              />
            </div>
          )}
          {(callerParticipantDetails?.status === CallingStatus.IN_PROGRESS ||
            callerParticipantDetails?.status === CallingStatus.RINGING ||
            callerParticipantDetails?.status === CallingStatus.RECORDING_VOICEMAIL) && (
            <div>
              <OngoingTag
                status={callerParticipantDetails?.status}
                isOutgoingCall={callerParticipantDetails.isCaller}
                openForceQuitCallModal={() => {
                  openForceQuitCallModal?.(callerParticipantDetails.id, callSid, otherParticipantDetails);
                }}
                hideCallQuitIcon={callerParticipantDetails?.status === CallingStatus.RECORDING_VOICEMAIL}
              />
            </div>
          )}
        </td>
      )}
    </tr>
  ) : (
    <tr
      onClick={handleSidePopupClose}
      className={`navigator-data-row ${isOnCallNavigator ? "night-border" : ""} ${
        callerParticipantDetails?.status === CallingStatus.IN_PROGRESS ||
        callerParticipantDetails?.status === CallingStatus.RECORDING_VOICEMAIL
          ? "green-ongoing-color"
          : ""
      } ${isBackupNavigator ? "purple-border" : ""} ${
        (callerParticipantDetails?.status === CallingStatus.MISSED ||
          callerParticipantDetails?.status === CallingStatus.BUSY ||
          callerParticipantDetails?.status === CallingStatus.NO_ANSWER) &&
        (callerParticipantDetails?.isMissedCallRead === false || !callerParticipantDetails?.isMissedCallRead)
          ? "not-read"
          : ""
      } ${
        (callerParticipantDetails?.status === CallingStatus.MISSED ||
          callerParticipantDetails?.status === CallingStatus.BUSY ||
          callerParticipantDetails?.status === CallingStatus.NO_ANSWER) &&
        callerParticipantDetails?.isMissedCallRead === true
          ? "missed"
          : ""
      }`}
    >
      <td>
        <InboundCallIcons participantDetail={callerParticipantDetails} />
      </td>
      <td onMouseEnter={() => setIsParticipantHovered(true)} onMouseLeave={() => setIsParticipantHovered(false)}>
        <div className="participants-section">
          <div className="participants-name">
            {participants.map(
              (participant: any, participantIndex: number) =>
                `${participant.id ? participant.name : participant.phoneNumber}${
                  participantIndex === participants.length - 1 ? "" : ", "
                }`
            )}
          </div>
          {participants.length === 1 && participants[0].type !== null && (
            <div className="participant-type">{participants[0].type}</div>
          )}
        </div>
        <div className="navigator-date">
          <div
            className="date-time-container"
            onMouseEnter={() => setIsIconHovered(true)}
            onMouseLeave={() => setIsIconHovered(false)}
          >
            {callStartTime}
            {isOnCallNavigator ? <Icon icon="OnCallNavigatorIcon" className="night-icon" size={13} /> : null}
            {isBackupNavigator ? <Icon icon="BackupNavigatorIcon" className="night-icon" size={13} /> : null}
            {isIcontHovered &&
              receivedBy &&
              ((isOnCallNavigator && !isBackupNavigator) || (!isOnCallNavigator && isBackupNavigator)) && (
                <TooltipSingleLine
                  isOnCallNavigator={isOnCallNavigator}
                  isBackupNavigator={isBackupNavigator}
                  receivedBy={receivedBy}
                  isHovered={true}
                />
              )}
          </div>
        </div>
      </td>
      <td>
        {callerParticipantDetails?.status === CallingStatus.MISSED ||
        callerParticipantDetails?.status === CallingStatus.NO_ANSWER ||
        callerParticipantDetails?.status === CallingStatus.BUSY ||
        callerParticipantDetails?.status === CallingStatus.IN_PROGRESS ||
        callerParticipantDetails?.status === CallingStatus.RECORDING_VOICEMAIL ? (
          <div className="empty-call-duration">-</div>
        ) : (
          getDurationOfCall(Number(duration))
        )}
        {isAtleastOnePatient() && (
          <span
            className="participant-info-button"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(setOpenParticipantsPopup({ open: true, participants }));
            }}
          >
            <Icon icon="participant-info" size={20} />
          </span>
        )}
      </td>
      <SidePopup
        isOpen={isOpen}
        onClose={() => {
          handleSidePopupClose();
        }}
        className="bottom participants-call-popup"
      >
        <div className="participants-name">
          {participants.map(
            (participant: any, participantIndex: number) =>
              `${participant.id ? participant.name : participant.phoneNumber}${
                participantIndex === participants.length - 1 ? "" : ", "
              }`
          )}
        </div>
        <div className="participants-call-duration">
          {callStartTime} |{" "}
          {callerParticipantDetails?.status === CallingStatus.MISSED ||
          callerParticipantDetails?.status === CallingStatus.NO_ANSWER ||
          callerParticipantDetails?.status === CallingStatus.BUSY ||
          callerParticipantDetails?.status === CallingStatus.IN_PROGRESS ||
          callerParticipantDetails?.status === CallingStatus.RECORDING_VOICEMAIL
            ? "-"
            : getDurationOfCall(Number(duration))}
        </div>
        {(callerParticipantDetails?.status === CallingStatus.MISSED ||
          callerParticipantDetails?.status === CallingStatus.NO_ANSWER ||
          callerParticipantDetails?.status === CallingStatus.BUSY) && (
          <div className={`icons ${isRowHovered || index === indexOfAudioPlayer ? "visible" : "hidden"}`}>
            <RowIcons
              index={index}
              indexOfAudioPlayer={indexOfAudioPlayer}
              setIndexOfAudioPlayer={setIndexOfAudioPlayer}
              recordingUri={recordingUri}
              participants={participants}
              clientID={clientID as string}
              isReply={isReplyButtonDisabled}
              voiceMailUrl={callerParticipantDetails?.voiceMailUrl}
              callerParticipantDetails={callerParticipantDetails}
              callStartTime={callStartTime}
            />
          </div>
        )}
        {(callerParticipantDetails?.status === CallingStatus.IN_PROGRESS ||
          callerParticipantDetails?.status === CallingStatus.RECORDING_VOICEMAIL ||
          callerParticipantDetails?.status === CallingStatus.RINGING) && (
          <div>
            <OngoingTag
              status={callerParticipantDetails?.status}
              isOutgoingCall={callerParticipantDetails.isCaller}
              openForceQuitCallModal={() => {
                openForceQuitCallModal?.(callerParticipantDetails.id, callSid, otherParticipantDetails);
              }}
            />
          </div>
        )}
      </SidePopup>
    </tr>
  );
};

export default CallLogRow;
